/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/assets/scss/global.scss"

import resourcesCategories from "./src/constants/resourcesCategories"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

let oldPathname = ""

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location
  if (Object.values(resourcesCategories).some((route) => route.url === pathname || route.url + "/" === pathname) && oldPathname.indexOf(resourcesCategories.all.url) !== -1) {
    oldPathname = pathname
    return false
  }
  oldPathname = pathname
  window.scrollTo(0, 0)
}
