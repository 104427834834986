export default {
  solutions: {
    agriculture: "/solutions/agriculture/",
    audio: "/solutions/audio/",
    robotics: "/solutions/autonomous-vehicles-robotics/",
    biomedical: "/solutions/biomedical-healthcare/",
    multimedia: "/solutions/multimedia/",
    safety: "/solutions/safety-security/",
  },
  docs: "https://docs.activeloop.ai/",
  pricing: "/pricing/",
  blogfooter: "/resources/blog/",
  resources: "/resources/",
  authorDavid: "/resources/davitbuniatyan/",
  authorGustav: "/resources/gustavvonzitzewitz/",
  jobs: "https://careers.activeloop.ai/",
  caseStudy: {
    intelinair: "/usecase/intelinair/",
    earthshot: "/usecase/earthshot/",
    ubenwa: "/usecase/ubenwa/",
    sweep: "/usecase/sweep/",
    askRoger: "/usecase/askroger/",
    tinymile: "/usecase/tinymile/",
    hercules: "/usecase/herculesai/",
    bayer: "/usecase/bayer/",
    flagship: "/usecase/flagshippioneering/",
    matterport: "/usecase/matterport/",
    medtech: "/usecase/medtech/",
  },
  about: "/about/",
  terms: "/terms/",
  privacy: "/privacy/",
  donotsell: "/donotsell/",
  contactUs: "/contact/",
  blog: "/resources/",
  tutorials: "/resources/tutorials/",
  releaseNotes: "/resources/releaseNotes/",
  news: "/resources/news/",
  deepLake: "http://deeplake.ai/",

  calculator: {
    main: "/calculator/",
    results: "/calculation-results/",
  },
  external: {
    github: "https://github.com/activeloopai/deeplake/",
    twitter: "https://twitter.com/activeloopai/",
    medium: "https://medium.com/activeloop/",
    linkedin: "https://www.linkedin.com/company/activeloop/",
    facebook: "https://www.facebook.com/activeloopai/",
    slack: "https://join.slack.com/t/hubdb/shared_invite/zt-29n6cz747-MhtbbYnF8W1D5hVDDL4gHg/",
    start: "https://docs.activeloop.ai/quickstart/",
  },
  app: {
    login: "https://app.activeloop.ai/login/",
    register: "https://app.activeloop.ai/register/",
    main: "https://app.activeloop.ai/",
  },
  home: "/",
}
