import routes from "./routes"
export default {
  all: {
    id: "allResources",
    name: "All Resources",
    url: routes.resources,
    sub_title: "Discover Deep Lake: Train & fine-tune large language models, & build AI products with LangChain. Gain expert insights & stay informed on Activeloop's latest advancements as a multi-modal vector database for AI. Deepen your AI impact with Deep Lake.",
    title: "Deep into Deep Lake: Master the Latest AI Tech with Expert Insights",
    meta_desc: "Discover Deep Lake: Train, Fine-Tune ML Models, & Build AI Products with LangChain. Master AI with Expert Tips & Tricks.",
  },
  blog: {
    id: "blog",
    name: "Blog",
    url: routes.resources + "blog/",
    description: "Blog desc",
    sub_title: "Build AI products faster with Deep Lake: gain expert insights on large language model training, fine-tuning, and dataset management from Activeloop's team.",
    title: "Deep Lake & Large Language Models: AI Model Training & Fine-Tuning",
    meta_desc: "Deep Lake & Large Language Models: Expert Strategies for AI Model Training, Fine-Tuning, & AI Dataset Management.",
  },
  tutorials: {
    id: "tutorials",
    name: "Tutorials",
    color: "#27CACF",
    url: routes.resources + "tutorials/",
    description: "Blog desc",
    sub_title: "Build state-of-the-art AI products with insider techniques in machine learning, large language model training & fine-tuning, as well as LangChain development.",
    title: "Deep Lake Tutorials: Machine Learning & Large Language Models",
    meta_desc: "Deep Lake Tutorials: Stay Ahead of the Game in Machine Learning, Large Language Models Training, & Fine-Tuning.",
  },
  langchain: {
    id: "langchain",
    name: "LangChain",
    color: "#50A14F",
    url: routes.resources + "langchain/",
    description: "Blog desc",
    sub_title: "Building with LangChain? Leverage the power of LangChain with our comprehensive guides, tutorials, & demo projects. With Deep Lake as your Vector Store, you can build multi-modal LangChain apps, host locally or on the cloud, & fine-tune your models.",
    title: "LangChain: Best LangChain Guides with Deep Lake Vector Store",
    meta_desc: "LangChain Guides, Tutorials, & Demo Projects: Unlock LangChain's Potential with Real-World Examples with Deep Lake",
  },
  llamaindex: {
    id: "llamaindex",
    name: "LlamaIndex",
    color: "#5B51D2",
    url: routes.resources + "llamaIndex/",
    description: "Blog desc",
    sub_title: "Building with advanced chat with data apps? Learn to use LlamaIndex for free with our comprehensive guides, tutorials, & hands-on projects. With Deep Lake as your Database for AI, you can build multi-modal LlamaIndex apps with high retrieval accuracy and mitigate Large Language Model hallucinations",
    title: "LlamaIndex: Top Free Retrieval Augmented Generation Guides with LlamaIndex and Deep Lake",
    meta_desc: "What is LlamaIndex? Top LlamaIndex Tutorials for Advanced Retrieval Augmented Generation. Use LLMs on Your Multi-Modal Data With LlamaIndex and Deep Lake",
    meta_title:"What is LlamaIndex? Best Free LlamaIndex Guides for RAG"
  },
  glossary: {
    id: "Glossary",
    name: "Glossary",
    color: "#FFC107",
    url: routes.resources + `glossary/`,
    description: "",
    sub_title: "Building with LangChain? Leverage the power of LangChain with our comprehensive guides, tutorials, & demo projects. With Deep Lake as your Vector Store, you can build multi-modal LangChain apps, host locally or on the cloud, & fine-tune your models.",
    title: "LangChain: Best LangChain Guides with Deep Lake Vector Store",
    meta_desc: "LangChain Guides, Tutorials, & Demo Projects: Unlock LangChain's Potential with Real-World Examples with Deep Lake",
  },
  events: {
    id: "events",
    name: "Events",
    url: routes.resources + "events/",
    disabled: true,
    sub_title: "",
    title: "",
    meta_desc: "",
  },
  podcast: {
    id: "podcast",
    name: "Podcast",
    url: routes.resources + "podcast/",
    disabled: true,
    description: "Blog desc",
    sub_title: "",
    title: "",
    meta_desc: "",
  },
  releaseNotes: {
    id: "releaseNotes",
    name: "Release Notes",
    color: "#e2006c",
    url: routes.resources + "releaseNotes/",
    description: "Blog desc",
    sub_title: "Stay up-to-date with Deep Lake. Explore new features, code enhancements, & bug fixes to streamline your AI product. Keep your AI projects or LLM training jobs running smoothly - read now!",
    title: "Deep Lake Release Notes: Discover Features & Enhancements",
    meta_desc: "Read Deep Lake Release Notes. Unveil New Features & Stay Ahead in Generative AI, LangChain & Large Language Models.",
  },
  news: {
    id: "news",
    name: "News",
    color: "#FF572E",
    url: routes.resources + "news/",
    description: "Blog desc",
    sub_title: "Get an inside look into the world of AI development with team Activeloop. Stay informed on the latest Deep Lake news, as weel as trends in machine learning, large language models, generative AI, & LangChain.",
    title: "Deep Lake & Activeloop News: Stay In-The-Loop with the Latest AI News",
    meta_desc: "See What's New in AI. Unlock Insights on Deep Lake, LLMs, Generative AI, & LangChain to Stay Ahead of the Curve",
  },
}
